.pomo-logo {
  width: 40px;
  height: 30px;
}

.header {
  width:100%;
  display: flex;
  justify-content: space-between;
}
